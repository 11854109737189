import Vue from 'vue';
import Metrika from 'vue-yandex-metrika';

import router from '@/router';
import store from '@/store';

if (store.getters.environment === 'production' && process.env.VUE_APP_METRIKA_KEY) {
  Vue.use(Metrika, {
    id: process.env.VUE_APP_METRIKA_KEY,
    router,
    debug: false,
    env: store.getters.environment,
    scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  });
}
