import Vue from 'vue';
import Meta from 'vue-meta';
import Router, { Route, RouteMeta } from 'vue-router';

import {
  authMiddleware,
  permissionMiddleware,
  privilegedMiddleware,
  publisherOnMobileMiddleware,
} from '@/routes/middlewares';
import { ReferrerService, UTMService } from '@/services/StorageService';
import store from '@/store';
import TagModel from '@/store/models/Tag';
import { SET_ERROR } from '@/store/types/mutations';

const AccrualRuleFixes = () =>
  import(
    /* webpackChunkName: "admin-panel" */ '@/views/Admin/AccrualRuleFixes/AccrualRuleFixes.vue'
  );

const AdminPage = () => import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/AdminPage.vue');
const AuditLogs = () => import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/AuditLogs.vue');
const Diversification = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/Diversification.vue');
const DomainModeration = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/DomainModeration.vue');
const GlobalSpotOptions = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/GlobalSpotOptions.vue');
const Logs = () => import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/Logs.vue');
const Moderation = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/Moderation.vue');
const Notifications = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/Notifications.vue');
const Batches = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/payments/Batches.vue');
const PaymentRequests = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/payments/PaymentRequests.vue');
const Rates = () => import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/Rates.vue');
const RevshareModeration = () =>
  import(
    /* webpackChunkName: "admin-panel" */ '@/views/Admin/revshare-moderation/RevshareModeration.vue'
  );
const LossesModeration = () =>
  import(
    /* webpackChunkName: "admin-panel" */ '@/views/Admin/losses-moderation/LossesModeration.vue'
  );
const Settings = () => import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/Settings.vue');
const OptimizationRules = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/SpotOptimizationRules.vue');
const Users = () => import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/users/Users.vue');
const UsersEdit = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/users/UsersEdit.vue');
const UsersList = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/users/UsersList.vue');
const UsersRestrictSuggestions = () =>
  import(/* webpackChunkName: "admin-panel" */ '@/views/Admin/UsersRestrictSuggestions.vue');
const GoogleAuth = () => import(/* webpackChunkName: "auth" */ '@/views/Auth/GoogleAuth.vue');
const VerifyPage = () => import(/* webpackChunkName: "auth" */ '@/views/Auth/VerifyPage.vue');
const AdNetworks = () => import(/* webpackChunkName: "common" */ '@/views/Common/AdNetworks.vue');
const LandingClickadilla = () =>
  import(/* webpackChunkName: "common" */ '@/views/Common/Landings/Clickadilla/Landing.vue');
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "common" */ '@/views/Common/Landings/Clickadilla/Privacy.vue');
const Terms = () =>
  import(/* webpackChunkName: "common" */ '@/views/Common/Landings/Clickadilla/Terms.vue');
const LandingClickadillaView = () =>
  import(/* webpackChunkName: "common" */ '@/views/Common/Landings/Clickadilla/View.vue');
const LandingMyBid = () =>
  import(/* webpackChunkName: "common" */ '@/views/Common/Landings/MyBid/Landing.vue');
const LandingMyBidPrivacyPolicy = () =>
  import(/* webpackChunkName: "common" */ '@/views/Common/Landings/MyBid/Privacy.vue');
const LandingMyBidTermsAndConditions = () =>
  import(/* webpackChunkName: "common" */ '@/views/Common/Landings/MyBid/Terms.vue');
const LandingMyBidView = () =>
  import(/* webpackChunkName: "common" */ '@/views/Common/Landings/MyBid/View.vue');
const OwnOffers = () => import(/* webpackChunkName: "common" */ '@/views/Common/OwnOffers.vue');
const Adformats = () =>
  import(/* webpackChunkName: "user" */ '@/views/User/adformats/Adformats.vue');
const FAQ = () => import(/* webpackChunkName: "user" */ '@/views/User/FAQ.vue');
const Referral = () => import(/* webpackChunkName: "user" */ '@/views/User/Referral.vue');
const Spots = () => import(/* webpackChunkName: "user" */ '@/views/User/spots/Spots.vue');
const SpotsEdit = () => import(/* webpackChunkName: "user" */ '@/views/User/spots/SpotsEdit.vue');
const SpotsList = () => import(/* webpackChunkName: "user" */ '@/views/User/spots/SpotsList.vue');
const ResetPassword = () => import(/* webpackChunkName: "v2-auth" */ '@/views/v2/Auth/Reset.vue');
const Signin = () => import(/* webpackChunkName: "v2-auth" */ '@/views/v2/Auth/Signin.vue');
const Signup = () => import(/* webpackChunkName: "v2-auth" */ '@/views/v2/Auth/Signup.vue');
const Statistics = () =>
  import(/* webpackChunkName: "v2-common" */ '@/views/v2/Common/Statistics.vue');
const NotFoundClickadilla = () =>
  import(/* webpackChunkName: "v2-error" */ '@/views/Error/NotFound.vue');
const NotFoundMyBid = () =>
  import(/* webpackChunkName: "v2-error" */ '@/views/v2/Error/NotFound.vue');
const AdCampaigns = () =>
  import(/* webpackChunkName: "v2-user" */ '@/views/v2/User/AdCampaigns.vue');
const Api = () => import(/* webpackChunkName: "v2-user" */ '@/views/v2/User/Api.vue');
const CookiesAgreement = () =>
  import(/* webpackChunkName: "v2-user" */ '@/views/v2/User/CookiesAgreement.vue');
const Landings = () =>
  import(/* webpackChunkName: "v2-user" */ '@/views/v2/User/landings/Landings.vue');
const LandingsForm = () =>
  import(/* webpackChunkName: "v2-user" */ '@/views/v2/User/landings/LandingsForm.vue');
const LandingsList = () =>
  import(/* webpackChunkName: "v2-user" */ '@/views/v2/User/landings/LandingsList.vue');
const Payments = () => import(/* webpackChunkName: "v2-user" */ '@/views/v2/User/Payments.vue');
const Profile = () => import(/* webpackChunkName: "v2-user" */ '@/views/v2/User/Profile.vue');

Vue.use(Router);
Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

const router = new Router({
  mode: 'history',
  base: '',
  routes: [
    {
      path: '/',
      name: 'View',
      component:
        process.env.VUE_APP_ORG_NAME === 'MyBid' ? LandingMyBidView : LandingClickadillaView,
      meta: {
        guest: true,
        landing: process.env.VUE_APP_ORG_NAME === 'MyBid',
        middlewares: [authMiddleware],
      },
      children: [
        {
          path: '',
          name: 'Landing',
          component: process.env.VUE_APP_ORG_NAME === 'MyBid' ? LandingMyBid : LandingClickadilla,
          redirect: process.env.VUE_APP_ORG_NAME === 'Onclicka'
            ? '/ad-codes'
            : '',
          meta: {
            guest: process.env.VUE_APP_ORG_NAME !== 'Onclicka',
            landing: process.env.VUE_APP_ORG_NAME === 'MyBid',
            middlewares: [authMiddleware],
          },
        },
        {
          path: 'privacy-policy',
          name: 'Privacy policy',
          component:
            process.env.VUE_APP_ORG_NAME === 'MyBid' ? LandingMyBidPrivacyPolicy : PrivacyPolicy,
          meta: {
            guest: process.env.VUE_APP_ORG_NAME !== 'Onclicka',
            landing: process.env.VUE_APP_ORG_NAME === 'MyBid',
            middlewares: [authMiddleware],
          },
        },
        {
          path: 'terms-and-conditions',
          name: 'Terms and conditions',
          redirect: process.env.VUE_APP_ORG_NAME === 'Onclicka'
            ? '/ad-codes'
            : '',
          component:
            process.env.VUE_APP_ORG_NAME === 'MyBid' ? LandingMyBidTermsAndConditions : Terms,
          meta: {
            guest: process.env.VUE_APP_ORG_NAME !== 'Onclicka',
            landing: process.env.VUE_APP_ORG_NAME === 'MyBid',
            middlewares: [authMiddleware],
          },
        },
      ],
    },

    {
      path: '/registration',
      redirect: 'signup',
    },

    {
      path: '/statistics',
      name: 'Statistics',
      component: Statistics,
      meta: {
        redesign: true,
        guest: false,
        middlewares: [authMiddleware, publisherOnMobileMiddleware],
      },
    },

    {
      path: '/ad-codes',
      name: 'AD codes',
      component: AdCampaigns,
      props: () => ({
        user: store.getters.user.data,
        section: 'user',
        permissions: store.getters.tagPermissions.data,
      }),
      meta: {
        guest: false,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/signup',
      name: 'Sign up',
      component: Signup,
      meta: {
        guest: true,
        redesign: true,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/register-by-invitation',
      name: 'Sign up by invite',
      component: Signup,
      meta: {
        guest: true,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/login',
      name: 'Login',
      component: Signin,
      props: route => ({ prevRoute: route.params.prevRoute }),
      meta: {
        guest: true,
        redesign: true,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/password-reset',
      name: 'Reset password',
      component: ResetPassword,
      meta: {
        guest: true,
        redesign: true,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/verify-email',
      name: 'Email verification',
      component: VerifyPage,
      meta: {
        guest: true,
      },
    },

    {
      path: '/profile-change-verification',
      name: 'Profile change verification',
      component: VerifyPage,
      meta: {
        guest: true,
      },
    },

    {
      path: '/confirm-email-change',
      name: 'Email confirmantion',
      component: VerifyPage,
      meta: {
        guest: true,
      },
    },

    {
      path: '/verify-email-change',
      name: 'Email change verification',
      component: VerifyPage,
      meta: {
        guest: true,
      },
    },

    {
      path: '/verify-yandex-token',
      name: 'Verify yandex token',
      component: VerifyPage,
      meta: {
        guest: true,
      },
    },

    {
      path: '/verify-google-token',
      name: 'Verify google token',
      component: VerifyPage,
      meta: {
        guest: true,
      },
    },

    {
      path: '/google-oauth2-callback',
      name: 'Google OAuth',
      component: GoogleAuth,
      meta: {
        guest: true,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/landings',
      component: Landings,
      meta: {
        guest: false,
        middlewares: [authMiddleware],
        redesign: true,
      },
      children: [
        {
          path: '',
          name: 'Landings list',
          component: LandingsList,
          props: () => ({
            mode: 'common',
          }),
          meta: {
            guest: false,
            middlewares: [authMiddleware],
            redesign: false,
          },
        },

        {
          path: 'add',
          name: 'Add landing',
          component: LandingsForm,
          props: () => ({
            mode: 'common',
          }),
          meta: {
            guest: false,
            middlewares: [authMiddleware],
            redesign: true,
          },
        },

        {
          path: 'edit/:id',
          name: 'Edit landing',
          component: LandingsForm,
          props: () => ({
            mode: 'common',
          }),
          meta: {
            guest: false,
            middlewares: [authMiddleware],
            redesign: true,
          },
        },
      ],
    },

    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        guest: false,
        redesign: true,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/adformats',
      name: 'Adformats',
      component: Adformats,
      meta: {
        guest: false,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/mediation',
      name: 'Mediation',
      component: AdNetworks,
      props: {
        permission: 'common',
      },
      meta: {
        guest: false,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/direct-offer',
      name: 'Direct offer',
      component: OwnOffers,
      props: {
        permission: 'common',
      },
      meta: {
        guest: false,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/cookies-agreement',
      name: 'Cookies agreement',
      component: CookiesAgreement,
      props: {},
      meta: {
        guest: false,
        middlewares: [authMiddleware],
      },
    },

    // === team only pages ===
    {
      path: '/admin',
      component: AdminPage,
      meta: {
        middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
      },
      children: [
        {
          path: 'users',
          component: Users,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
          children: [
            {
              path: '',
              name: 'Users list',
              component: UsersList,
              meta: {
                guest: false,
                middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
              },
            },

            {
              path: 'edit/:id',
              name: 'Edit user',
              component: UsersEdit,
              meta: {
                guest: false,
                middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
              },
            },
          ],
        },

        {
          path: 'statistics',
          name: 'Admin statistics',
          component: Statistics,
          meta: {
            redesign: true,
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'global-spot-options',
          name: 'Global spot options',
          component: GlobalSpotOptions,
          props: {
            section: 'admin',
          },
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'diversification',
          name: 'Diversification',
          component: Diversification,
          props: {
            section: 'admin',
          },
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'domain-moderation',
          name: 'Domain moderation',
          component: DomainModeration,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'revshare-moderation',
          name: 'Revshare moderation',
          component: RevshareModeration,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'losses-moderation',
          name: 'publisher_fallout_data',
          component: LossesModeration,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'accrual-rule-fixes',
          name: 'Accrual rule fixes',
          component: AccrualRuleFixes,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'accrual-rules-moderation',
          name: 'Accrual rules moderation',
          component: Moderation,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'audit-logs',
          name: 'Audit logs',
          component: AuditLogs,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },
        // === admin spot-related pages ===
        {
          path: 'spots',
          component: Spots,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
          children: [
            {
              path: '',
              name: 'Admin spots list',
              component: SpotsList,
              props: {
                section: 'admin',
              },
              meta: {
                guest: false,
                middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
              },
            },
          ],
        },

        {
          path: 'spot-optimization-rules',
          name: 'Spot optimization rules',
          component: OptimizationRules,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'mediation',
          name: 'Admin mediation',
          component: AdNetworks,
          props: {
            permission: 'advanced',
          },
          meta: {
            guest: false,
            middlewares: [authMiddleware],
          },
        },

        {
          path: '/admin/landings',
          component: Landings,
          name: 'Admin landings',
          meta: {
            guest: false,
            middlewares: [authMiddleware],
            redesign: false,
          },
          children: [
            {
              path: '',
              name: 'Admin landings list',
              component: LandingsList,
              props: () => ({
                mode: 'advanced',
              }),
              meta: {
                guest: false,
                middlewares: [authMiddleware],
                redesign: false,
              },
            },

            {
              path: 'add',
              name: 'Admin add landing',
              component: LandingsForm,
              props: () => ({
                mode: 'advanced',
              }),
              meta: {
                guest: false,
                middlewares: [authMiddleware],
                redesign: true,
              },
            },

            {
              path: 'edit/:id',
              name: 'Admin edit landing',
              props: () => ({
                mode: 'advanced',
              }),
              component: LandingsForm,
              meta: {
                guest: false,
                middlewares: [authMiddleware],
                redesign: true,
              },
            },
          ],
        },

        {
          path: 'direct-offer',
          name: 'Admin direct offer',
          component: OwnOffers,
          props: {
            permission: 'advanced',
          },
          meta: {
            guest: false,
            middlewares: [authMiddleware],
          },
        },

        {
          path: 'settings',
          name: 'Settings',
          component: Settings,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'payments',
          name: 'Payment requests',
          component: PaymentRequests,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'batches',
          name: 'Batches',
          component: Batches,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'logs',
          name: 'Logs',
          component: Logs,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'notifications',
          name: 'Admin notifications',
          component: Notifications,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'stats-adformat-rates',
          name: 'Rates',
          component: Rates,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },

        {
          path: 'users-restrict-suggestions',
          name: 'Users restrict suggestions',
          component: UsersRestrictSuggestions,
          meta: {
            guest: false,
            middlewares: [authMiddleware, permissionMiddleware, privilegedMiddleware],
          },
        },
      ],
    },

    {
      path: '/payments',
      name: 'Payments',
      component: Payments,
      meta: {
        guest: false,
        redesign: true,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/referral',
      name: 'Referral',
      component: Referral,
      meta: {
        guest: false,
        middlewares: [authMiddleware],
      },
    },

    // === spot-related pages ===
    {
      path: '/spots',
      component: Spots,
      meta: {
        guest: false,
        middlewares: [authMiddleware],
      },
      children: [
        {
          path: '',
          name: 'Spots list',
          component: SpotsList,
          props: {
            section: 'user',
          },
          meta: {
            guest: false,
            middlewares: [authMiddleware],
          },
        },

        {
          path: 'edit/:adFormat/:spotId/',
          name: 'Edit spot',
          component: SpotsEdit,
          props: () => ({
            tag: TagModel,
            section: 'user',
            permissions: store.getters.tagPermissions.data,
          }),
          meta: {
            guest: false,
            middlewares: [authMiddleware],
          },
        },
      ],
    },

    {
      path: '/api',
      name: 'API',
      component: Api,
      meta: {
        guest: false,
        redesign: process.env.VUE_APP_UI_VERSION === '2',
        middlewares: [authMiddleware],
      },
    },

    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ,
      meta: {
        guest: false,
        middlewares: [authMiddleware],
      },
    },

    {
      path: '*',
      component: process.env.VUE_APP_ORG_NAME === 'MyBid' ? NotFoundMyBid : NotFoundClickadilla,
    },
  ],
});

export default router;

const parseUTM = ({ query }: Route) => {
  const { utm_source, utm_medium, utm_campaign, utm_content, utm_term } = query;

  const utms: Record<string, any> = { utm_source, utm_medium, utm_campaign, utm_content, utm_term };
  const hasUTMs = Object.keys(utms).some(key => utms[key]);

  const referrer = ReferrerService.getReferrer();

  if (
    !hasUTMs &&
    referrer &&
    !referrer.includes(String(process.env.VUE_APP_FRONTEND)) &&
    !referrer.includes(String(process.env.VUE_APP_ORG_SHORT_URL))
  ) {
    utms.utm_source = 'referral';
    utms.utm_campaign = new URL(referrer).origin;

    if (/(yandex|google)/g.test(referrer)) {
      utms.utm_source = 'se';
    }
  }

  return utms;
};

router.beforeEach(async (to, from, next) => {
  store.commit(SET_ERROR, null);
  UTMService.setUTM(parseUTM(to));
  const { middlewares } = to?.meta as RouteMeta & {
    middlewares: Function[];
  };
  if ((to.path !== from.path || to.path === '/') && middlewares) {
    for (const middleware of middlewares) {
      await middleware({ to, from, next });
    }
  } else next();
});
