import { rest } from 'msw';

export const statisticsServiceHandlers = [
  rest.get('http://localhost:8080/api/stats-reports', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: [
          {
            name: 'Statistics',
            slug: 'statistics',
            read_only: true,
          },
          {
            name: 'Antifraud',
            slug: 'antifraud',
            read_only: true,
          },
        ],
      })
    );
  }),

  rest.get('http://localhost:8080/api/stats-metadata/:slug', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: {
          statsUrl: '/api/stats/statistics',
          filters: [
            {
              name: 'adformat',
              title: 'Adformat',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              dictionaryUrl: '/api/stats-dictionaries/adformats',
              filterType: 'multi',
              dictionaryPreset: [],
            },
            {
              name: 'manager_email',
              title: 'Manager email',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/managers',
              presets: ['noManager'],
              dictionaryPreset: {
                'No manager': '(not set)',
              },
            },
            {
              name: 'user_email',
              title: 'Publisher email',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              manual_filter: 'user_email',
              dictionaryUrl: '/api/stats-dictionaries/users',
              dependsOn: ['manager_email'],
              dictionaryPreset: [],
            },
            {
              name: 'user_email',
              title: 'Publisher email',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              baseFilter: 'user_email',
              dictionaryPreset: [],
            },
            {
              name: 'user_id',
              title: 'User id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              dictionaryUrl: '/api/stats-dictionaries/statics/user_id',
              dictionaryPreset: [],
            },
            {
              name: 'spot_name',
              title: 'Spot Name',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/spots',
              dependsOn: ['manager_email', 'user_email', 'adformat', 'tag_name'],
              dictionaryPreset: [],
            },
            {
              name: 'spot_id',
              title: 'Spot Id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              manual_filter: 'spot_id',
              dictionaryUrl: '/api/stats-dictionaries/spot_id',
              dependsOn: ['manager_email', 'user_email', 'adformat', 'tag_name'],
              dictionaryPreset: [],
            },
            {
              name: 'spot_id',
              title: 'Spot Id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              baseFilter: 'spot_id',
              dictionaryPreset: [],
            },
            {
              name: 'tag_name',
              title: 'Tag Name',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/tags',
              dependsOn: ['manager_email', 'user_email'],
              manual_filter: 'tag_id',
              dictionaryPreset: [],
            },
            {
              name: 'tag_id',
              title: 'Tag id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              baseFilter: 'tag_name',
              dictionaryPreset: [],
            },
            {
              name: 'account_name',
              title: 'Advertiser',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/account_names',
              manual_filter: 'account_id',
              dictionaryPreset: [],
            },
            {
              name: 'account_id',
              title: 'Advertiser id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              baseFilter: 'account_name',
              dictionaryPreset: [],
            },
            {
              name: 'adv_manager',
              title: 'Adv Manager',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/adv_managers',
              presets: ['noManager'],
              dictionaryPreset: {
                'No manager': '(not set)',
              },
            },
            {
              name: 'campaign_name',
              title: 'Campaign',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              manual_filter: 'campaign_id',
              dictionaryUrl: '/api/stats-dictionaries/campaign_names',
              presets: ['none'],
              dictionaryPreset: {
                None: '(not set)',
              },
            },
            {
              name: 'campaign_id',
              title: 'Campaign Id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              baseFilter: 'campaign_name',
              dictionaryPreset: [],
            },
            {
              name: 'domain',
              title: 'Domain',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/domains',
              dictionaryPreset: [],
            },
            {
              name: 'device',
              title: 'Device',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/device',
              dictionaryPreset: [],
            },
            {
              name: 'operating_system',
              title: 'Operating System',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/operating_system',
              dictionaryPreset: [],
            },
            {
              name: 'browser',
              title: 'Browser',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/browser',
              dictionaryPreset: [],
            },
            {
              name: 'country',
              title: 'Country',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/country',
              manual_filter: 'country',
              dictionaryPreset: [],
            },
            {
              name: 'country',
              title: 'Country',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              baseFilter: 'country',
              dictionaryPreset: [],
            },
            {
              name: 'click_type',
              title: 'Click Type',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/click_type',
              dictionaryPreset: [],
            },
            {
              name: 'feed_type',
              title: 'Feed Type',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/feed_type',
              dictionaryPreset: [],
            },
            {
              name: 'is_adblock',
              title: 'Is Adblock',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/is_adblock',
              dictionaryPreset: [],
            },
            {
              name: 'is_cryptocurrency',
              title: 'Is Cryptocurrency',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/is_cryptocurrency',
              dictionaryPreset: [],
            },
            {
              name: 'is_dsp',
              title: 'Is DSP',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/is_dsp',
              dictionaryPreset: [],
            },
            {
              name: 'is_shortener_tag',
              title: 'URL Shortener stats filter',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/is_shortener_tag',
              dictionaryPreset: [],
            },
            {
              name: 'is_own',
              title: 'Is Own',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/is_own',
              dictionaryPreset: [],
            },
            {
              name: 'is_ban',
              title: 'Is Banned',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/is_ban',
              dictionaryPreset: [],
            },
            {
              name: 'is_mediation',
              title: 'Is Mediation',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/is_mediation',
              dictionaryPreset: [],
            },
            {
              name: 'is_hidden_spot',
              title: 'Is Hidden Spot',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/is_hidden_spot',
              dictionaryPreset: [],
            },
            {
              name: 'spot_type',
              title: 'Spot Type',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/spot_type',
              dictionaryPreset: [],
            },
            {
              name: 'site_id',
              title: 'Site Id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              dictionaryUrl: '/api/stats-dictionaries/statics/site_id',
              dictionaryPreset: [],
            },
            {
              name: 'network_name',
              title: 'Adnetwork',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              dictionaryUrl: '/api/stats-dictionaries/adnetworks',
              filterType: 'multi',
              dictionaryPreset: [],
            },
            {
              name: 'network_id',
              title: 'Adnetwork Id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              baseFilter: 'network_name',
              dictionaryPreset: [],
            },
            {
              name: 'domain_moderation_status_name',
              title: 'Domain moderation status',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/domain_moderation_status_name',
              dictionaryPreset: [],
            },
            {
              name: 'tag_category',
              title: 'Tag category',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              dictionaryUrl: '/api/stats-dictionaries/tag_categories',
              filterType: 'multi',
              dictionaryPreset: [],
            },
            {
              name: 'tag_label',
              title: 'Tag label',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/tag_label',
              dictionaryPreset: [],
            },
            {
              name: 'tag_custom_label',
              title: 'Tag custom label',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              dictionaryUrl: '/api/stats-dictionaries/custom_tag_labels',
              filterType: 'multi',
              dictionaryPreset: [],
            },
            {
              name: 'tag_test_name',
              title: 'Tag AB test name',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              dictionaryUrl: '/api/stats-dictionaries/tag_tests',
              filterType: 'multi',
              dictionaryPreset: [],
            },
            {
              name: 'tag_type',
              title: 'Tag AB test version',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/tag_type',
              dictionaryPreset: [],
            },
            {
              name: 'spot_labels',
              title: 'Spot Labels',
              type: 'dimensions',
              operators: ['=@', '!=@'],
              default_operator: '=@',
              filterType: 'multi',
              dictionaryUrl: '/api/stats-dictionaries/statics/spot_labels',
              dictionaryPreset: [],
            },
            {
              name: 'original_adformat',
              title: 'Original Adformat',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              dictionaryUrl: '/api/stats-dictionaries/adformats',
              filterType: 'multi',
              dictionaryPreset: [],
            },
            {
              name: 'original_spot_id',
              title: 'Original Spot Id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'multi',
              manual_filter: 'original_spot_id',
              dictionaryUrl: '/api/stats-dictionaries/spot_id',
              dependsOn: ['manager_email', 'user_email', 'adformat', 'tag_name'],
              dictionaryPreset: [],
            },
            {
              name: 'original_spot_id',
              title: 'Original Spot Id',
              type: 'dimensions',
              operators: ['=', '!='],
              default_operator: '=',
              filterType: 'text',
              baseFilter: 'original_spot_id',
              dictionaryPreset: [],
            },
            {
              name: 'banned_adformat',
              title: 'Exclude users with banned adformats',
              type: 'dimensions',
              operators: ['!='],
              default_operator: '!=',
              dictionaryUrl: '/api/stats-dictionaries/adformats',
              filterType: 'multi',
              dictionaryPreset: [],
            },
          ],
          metrics: [
            {
              name: 'installs',
              title: 'Installs',
              description: 'Number of installs',
              format: '0,0',
              sortOrder: '2050',
              group: 'visitors',
              labels: {
                filters: {
                  adformat: ['Web Push', 'Calendar', 'Extension', 'Mobile App'],
                },
              },
            },
            {
              name: 'impressions',
              title: 'Impressions',
              description: 'Impressions',
              format: '0,0',
              sortOrder: '1050',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'default_impressions',
              title: 'Default Impressions',
              description: 'Default Impressions',
              format: '0,0',
              sortOrder: '1050',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'impressions_without_optimization',
              title: 'Impressions Without Optimization',
              description: 'Impressions Without Optimization',
              format: '0,0',
              sortOrder: '1050',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'task_successes',
              title: 'Task Successes',
              description: 'Task Successes',
              format: '0,0',
              sortOrder: '1050',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'impressions_admin',
              title: 'Admin Impressions',
              description: 'Impressions',
              format: '0,0',
              sortOrder: '1050',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'impressions_admin_without_optimization',
              title: 'Admin Impressions Without Optimization',
              description: 'Admin Impressions Without Optimization',
              format: '0,0',
              sortOrder: '1050',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'clicks',
              title: 'Clicks',
              description: 'Clicks',
              format: '0,0',
              sortOrder: '1100',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'clicks_without_optimization',
              title: 'Clicks Without Optimization',
              description: 'Clicks Without Optimization',
              format: '0,0',
              sortOrder: '1100',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'revenue_on_clicks',
              title: 'Gross revenue on clicks',
              description: 'Gross revenue on clicks',
              format: '$ 0,0[.]00',
              sortOrder: '3000',
              group: 'other',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'revenue_on_impressions',
              title: 'Gross revenue on impressions',
              description: 'Gross revenue on impressions',
              format: '$ 0,0[.]00',
              sortOrder: '3050',
              group: 'other',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'revenue_on_task_successes',
              title: 'Gross revenue on Task Successes',
              description: 'Gross revenue on Task Successes',
              format: '$ 0,0[.]00',
              sortOrder: '3050',
              group: 'other',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'revenue_on_installs',
              title: 'Gross revenue on Installs',
              description: 'Gross revenue on Installs',
              format: '$ 0,0[.]00',
              sortOrder: '3050',
              group: 'other',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'revenue',
              title: 'Revenue',
              description: 'Revenue',
              format: '$ 0,0[.]00',
              sortOrder: '4000',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'original_revenue',
              title: 'Original Revenue',
              description: 'Original Revenue',
              format: '$ 0,0[.]00',
              sortOrder: '4000',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'revshare_cost',
              title: 'Revshare Cost',
              description: 'Revshare Cost',
              format: '$ 0,0[.]00',
              sortOrder: '4050',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'revshare_cost_with_money_cuts',
              title: 'Revshare Cost Only With Money Cuts',
              description: 'Revshare Cost Only With Money Cuts',
              format: '$ 0,0[.]00',
              sortOrder: '4050',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'money_cut',
              title: 'Money Cut',
              description: 'Money Cut',
              format: '$ 0,0[.]00',
              sortOrder: '4050',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'fix_cpm_cost',
              title: 'Fix CPM cost',
              description: 'Fix CPM cost',
              format: '$ 0,0[.]00',
              sortOrder: '4050',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'fix_cpc_cost',
              title: 'Fix CPC cost',
              description: 'Fix CPC cost',
              format: '$ 0,0[.]00',
              sortOrder: '4050',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'admin_revshare_cost',
              title: 'Admin Revshare Cost',
              description: 'Admin Revshare Cost',
              format: '$ 0,0[.]00',
              sortOrder: '4050',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'subscribers',
              title: 'Subscribers',
              description: 'Number of subscribers',
              format: '0,0',
              sortOrder: '2050',
              group: 'visitors',
              labels: {
                filters: {
                  adformat: ['Web Push', 'Calendar'],
                },
              },
            },
            {
              name: 'subscription_requests',
              title: 'Sub requests',
              description: 'Requests of subscription dialog box',
              format: '0,0',
              sortOrder: '2100',
              group: 'visitors',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'visits',
              title: 'Visits',
              description: 'Number of visits',
              format: '0,0',
              sortOrder: '2050',
              group: 'visitors',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_visits',
              title: 'YM Visits',
              description: 'Number of YM visits',
              format: '0,0',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_users',
              title: 'YM Users',
              description: 'Number of YM distinct users',
              format: '0,0',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_views',
              title: 'YM Views',
              description: 'Number of YM views',
              format: '0,0',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_views_per_session',
              title: 'YM Views per session',
              description: 'YM Average views per session',
              format: '0,0',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_rejections',
              title: 'YM Rejected visits',
              description: 'YM Rejected visits',
              format: '0,0',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'percent_ym_rejections',
              title: 'YM Rejected visits (%)',
              description: 'YM Percent of rejected visits (less 15 sec.)',
              format: '0,0[.]00 %',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_user_engagement',
              title: 'YM Average visit time',
              description: 'YM Average visit duration',
              format: '0,0',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_bot_visits',
              title: 'YM Robot visits',
              description: 'YM Robot visits',
              format: '0,0',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'percent_ym_bot_visits',
              title: 'YM Robot visits (%)',
              description: 'YM percent of visits suspected to be robots',
              format: '0,0[.]00 %',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_js_visits',
              title: 'YM JavaScript visits',
              description: 'YM JavaScript visits',
              format: '0,0',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'percent_ym_js_visits',
              title: 'YM JavaScript visits (%)',
              description: 'YM Rate of visits and hits with JavaScript support',
              format: '0,0[.]00 %',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_cookie_visits',
              title: 'YM Cookies visits',
              description: 'YM Cookies visits',
              format: '0,0',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'percent_ym_cookie_visits',
              title: 'YM Cookies visits (%)',
              description: 'YM Rate of visits and hits with cookies enabled',
              format: '0,0[.]00 %',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ga_visits',
              title: 'GA Visits',
              description: 'Number of GA visits',
              format: '0,0',
              sortOrder: '2050',
              group: 'GA_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ga_users',
              title: 'GA Users',
              description: 'Number of GA distinct users',
              format: '0,0',
              sortOrder: '2050',
              group: 'GA_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ga_views',
              title: 'GA Views',
              description: 'Number of GA views',
              format: '0,0',
              sortOrder: '2050',
              group: 'GA_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ga_views_per_session',
              title: 'GA Views per session',
              description: 'Number of GA views per session',
              format: '0,0',
              sortOrder: '2050',
              group: 'GA_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ga_rejections',
              title: 'GA Rejected visits',
              description: 'GA Rejected visits',
              format: '0,0',
              sortOrder: '2050',
              group: 'GA_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'percent_ga_rejections',
              title: 'GA Rejected visits (%)',
              description: 'GA Rate of rejected sessions',
              format: '0,0[.]00 %',
              sortOrder: '2050',
              group: 'GA_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ga_user_engagement',
              title: 'GA Average visit time',
              description: 'GA Average visit duration',
              format: '0,0',
              sortOrder: '2050',
              group: 'GA_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'adnetworks_impressions',
              title: 'Mediation Impressions',
              description: 'Mediation Impressions',
              format: '0,0',
              sortOrder: '1050',
              group: 'mediation',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'adnetworks_clicks',
              title: 'Mediation Clicks',
              description: 'Mediation Clicks',
              format: '0,0',
              sortOrder: '1100',
              group: 'mediation',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'network_revenue',
              title: 'Mediation Revenue',
              description: 'Mediation Revenue',
              format: '$ 0,0[.]00',
              sortOrder: '3050',
              group: 'mediation',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'network_revenue_with_revshare',
              title: 'Mediation Revenue With Revshare',
              description: 'Mediation Revenue with revshare',
              format: '$ 0,0[.]00',
              sortOrder: '3050',
              group: 'mediation',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'network_money',
              title: 'Mediation Money',
              description: 'Mediation Money',
              format: '$ 0,0[.]00',
              sortOrder: '3050',
              group: 'mediation',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'network_profit',
              title: 'Mediation Profit',
              description: 'Mediation Profit',
              format: '$ 0,0[.]00',
              sortOrder: '3050',
              group: 'mediation',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'default_revenue',
              title: 'Default Revenue',
              description: 'Default Revenue',
              format: '$ 0,0[.]00',
              sortOrder: '3000',
              group: 'other',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'original_default_revenue',
              title: 'Origina Default Revenue',
              description: 'Original Default Revenue',
              format: '$ 0,0[.]00',
              sortOrder: '4000',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'default_cost',
              title: 'Default Cost',
              description: 'Default Cost',
              format: '$ 0,0[.]00',
              sortOrder: '4050',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'tag_views',
              title: 'Tag Views',
              description: 'Tag Views',
              format: '0,0',
              sortOrder: '2100',
              group: 'visitors',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'dsp_pay_in',
              title: 'Pay in',
              description: 'Pay in',
              format: '0.0',
              sortOrder: '2100',
              group: 'money',
              labels: {
                impersonal: true,
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'dsp_pay_out',
              title: 'Pay out',
              description: 'Pay out',
              format: '0.0',
              sortOrder: '2100',
              group: 'money',
              labels: {
                impersonal: true,
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'dsp_revenue',
              title: 'DSP revenue',
              description: 'DSP revenue',
              format: '0.0',
              sortOrder: '2100',
              group: 'money',
              labels: {
                impersonal: true,
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'money_raises',
              title: 'Money Raises',
              description: 'Money Raises',
              format: '$ 0,0[.]00',
              sortOrder: '1',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
                divide: 10000,
              },
            },
            {
              name: 'ym_arpu',
              title: 'YM ARPU',
              description: 'YM ARPU',
              format: '0,0[.]00000',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ym_impu',
              title: 'YM IMPU',
              description: 'YM IMPU',
              format: '0,0[.]00000',
              sortOrder: '2050',
              group: 'YM_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ga_arpu',
              title: 'GA ARPU',
              description: 'GA ARPU',
              format: '0,0[.]00000',
              sortOrder: '2050',
              group: 'GA_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ga_impu',
              title: 'GA IMPU',
              description: 'GA IMPU',
              format: '0,0[.]00000',
              sortOrder: '2050',
              group: 'GA_metrics',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'total_impressions',
              title: 'Total Impressions',
              description: 'Total Impressions',
              format: '0,0',
              sortOrder: '1050',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'total_clicks',
              title: 'Total Clicks',
              description: 'Total Clicks',
              format: '0,0',
              sortOrder: '1100',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ctr',
              title: 'CTR',
              description: 'Clicks / Impressions * 100%',
              format: '0,0[.]00 %',
              sortOrder: '1150',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ctr_admin',
              title: 'CTR',
              description: 'Clicks / Impressions * 100%',
              format: '0,0[.]00 %',
              sortOrder: '1150',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cr',
              title: 'Conversion Rate',
              description: 'Conversion Rate',
              format: '0,0[.]00 %',
              sortOrder: '2150',
              group: 'visitors',
              labels: {
                filters: {
                  adformat: ['Web Push', 'Calendar'],
                },
              },
            },
            {
              name: 'total_revenue',
              title: 'Total Revenue',
              description: 'TotalRevenue',
              format: '$ 0,0[.]00',
              sortOrder: '4000',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'total_cpcm_admin',
              title: 'Total CPCM',
              description: 'Total revenue / Total clicks * 1000',
              format: '$ 0,0[.]00',
              sortOrder: '1150',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'total_cpm_admin',
              title: 'Total CPM',
              description: 'Total revenue / Total impressions * 1000',
              format: '$ 0,0[.]00',
              sortOrder: '1150',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'total_ctr',
              title: 'Total CTR',
              description: 'Total clicks / Total impressions * 100',
              format: '0,0[.]00 %',
              sortOrder: '1150',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'fix_cpm_profit',
              title: 'Fix CPM profit',
              description: 'Fix CPM profit',
              format: '$ 0,0[.]00',
              sortOrder: '4050',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'fix_cpc_profit',
              title: 'Fix CPC profit',
              description: 'Fix CPC profit',
              format: '$ 0,0[.]00',
              sortOrder: '4050',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'admin_revshare_profit',
              title: 'Admin Revshare Profit',
              description: 'Admin Revshare Profit',
              format: '$ 0,0[.]00',
              sortOrder: '4100',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'revshare_profit',
              title: 'Revshare Profit',
              description: 'Revshare Profit',
              format: '$ 0,0[.]00',
              sortOrder: '4100',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'profit',
              title: 'Profit',
              description: 'Profit',
              format: '$ 0,0[.]00',
              sortOrder: '4100',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'redestribution_profit',
              title: 'Redestribution Profit',
              description: 'Redestribution Profit',
              format: '$ 0,0[.]00',
              sortOrder: '4100',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'roi',
              title: 'ROI',
              description: 'ROI',
              format: '0,0[.]00 %',
              sortOrder: '4150',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'roi_admin',
              title: 'ROI Admin',
              description: 'ROI Admin',
              format: '0,0[.]00 %',
              sortOrder: '4150',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ecpm',
              title: 'eCPM',
              description: 'eCPM',
              format: '$ 0.0[00000]',
              sortOrder: '4200',
              group: 'derivatives',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'ecpm_without_optimization',
              title: 'eCPM Without Optimization',
              description: 'eCPM Without Optimization',
              format: '$ 0.0[00000]',
              sortOrder: '4200',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpm',
              title: 'CPM',
              description: 'CPM',
              format: '$ 0.0[00000]',
              sortOrder: '4250',
              group: 'derivatives',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpm_without_optimization',
              title: 'CPM Without Optimization',
              description: 'CPM Without Optimization',
              format: '$ 0.0[00000]',
              sortOrder: '4250',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpm_admin',
              title: 'Admin CPM',
              description: 'Admin CPM',
              format: '$ 0.0[00000]',
              sortOrder: '4250',
              group: 'derivatives',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpm_admin_without_optimization',
              title: 'Admin CPM Without Optimization',
              description: 'Admin CPM Without Optimization',
              format: '$ 0.0[00000]',
              sortOrder: '4250',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'epcm',
              title: 'EPCm',
              description: 'EPC * 1000',
              format: '$ 0.0[00000]',
              sortOrder: '4300',
              group: 'derivatives',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'epcm_without_optimization',
              title: 'EPCm Without Optimization',
              description: 'EPC * 1000 Without Optimization',
              format: '$ 0.0[00000]',
              sortOrder: '4300',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpcm',
              title: 'CPCm',
              description: 'CPC * 1000',
              format: '$ 0.0[00000]',
              sortOrder: '4350',
              group: 'derivatives',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpcm_without_optimization',
              title: 'CPCm Without Optimization',
              description: 'CPC * 1000 Without Optimization',
              format: '$ 0.0[00000]',
              sortOrder: '4350',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpcm_admin',
              title: 'CPCm Admin',
              description: 'CPC * 1000',
              format: '$ 0.0[00000]',
              sortOrder: '4350',
              group: 'derivatives',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'epc',
              title: 'EPC',
              description: 'EPC',
              format: '$ 0.0[00000]',
              sortOrder: '4400',
              group: 'derivatives',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'epc_without_optimization',
              title: 'EPC Without Optimization',
              description: 'EPC Without Optimization',
              format: '$ 0.0[00000]',
              sortOrder: '4400',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpc',
              title: 'CPC',
              description: 'CPC',
              format: '$ 0.0[00000]',
              sortOrder: '4450',
              group: 'derivatives',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpc_admin',
              title: 'Admin CPC',
              description: 'Admin CPC',
              format: '$ 0.0[00000]',
              sortOrder: '4250',
              group: 'derivatives',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpc_without_optimization',
              title: 'CPC Without Optimization',
              description: 'CPC Without Optimization',
              format: '$ 0.0[00000]',
              sortOrder: '4450',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'cpc_admin_without_optimization',
              title: 'CPC Admin Without Optimization',
              description: 'CPC Without Optimization',
              format: '$ 0.0[00000]',
              sortOrder: '4450',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'bid_requests',
              title: 'Bid requests',
              description: 'Number of bid requests',
              format: '0,0',
              sortOrder: '1000',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'fillrate',
              title: 'Fillrate',
              description: 'Fillrate',
              format: '0,0[.]00 %',
              sortOrder: '950',
              group: 'traffic',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'money',
              title: 'Money',
              description: 'Money',
              format: '$ 0,0[.]00',
              sortOrder: '4100',
              group: 'money',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
            {
              name: 'money_without_optimization',
              title: 'Money Without Optimization',
              description: 'Money Without Optimization',
              format: '$ 0,0[.]00',
              sortOrder: '4100',
              group: 'metrics_without_optimizations',
              labels: {
                filters: {
                  adformat: [
                    'Native',
                    'Web Push',
                    'Inpage Push',
                    'Calendar',
                    'Popunder',
                    'In-stream ad',
                    'Banner',
                    'Extension',
                    'Mobile App',
                    'InApp',
                    'Interstitial',
                    'Direct Link',
                  ],
                },
              },
            },
          ],
          dimensions: [
            {
              name: 'adformat',
              title: 'Ad Format',
              description: 'Ad Format',
              group: 'default',
              labels: [],
            },
            {
              name: 'original_adformat',
              title: 'Original Ad Format',
              description: 'Original Ad Format',
              group: 'default',
              labels: [],
            },
            {
              name: 'banned_adformat',
              title: 'Banned AdFormat',
              description: 'Banned AdFormat',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'admin_adformat',
              title: 'Admin Ad Format',
              description: 'Admin Ad Format',
              group: 'default',
              labels: [],
            },
            {
              name: 'adformat_raw',
              title: 'Raw Ad Format',
              description: 'Raw Ad Format',
              group: 'default',
              labels: [],
            },
            {
              name: 'date',
              title: 'Date',
              description: 'Date YYYY-MM-DD',
              group: 'date',
              labels: [],
            },
            {
              name: 'year',
              title: 'Year',
              description: 'Year',
              group: 'date',
              labels: [],
            },
            {
              name: 'month',
              title: 'Month',
              description: 'Month',
              group: 'date',
              labels: [],
            },
            {
              name: 'spot_id',
              title: 'Spot id',
              description: 'Spot id',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'original_spot_id',
              title: 'Original Spot id',
              description: 'Original Spot id',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'short_id',
              title: 'Short id',
              description: 'Short id',
              group: 'default',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'ym_counter_id',
              title: 'YM Counter id',
              description: 'YM Counter id',
              group: 'default',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'gender',
              title: 'Gender of visitors',
              description: 'Gender of visitors',
              group: 'default',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'age',
              title: 'Age interval of visitors',
              description: 'Age interval of visitors',
              group: 'default',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'ga_property_id',
              title: 'GA Property id',
              description: 'GA Property id',
              group: 'default',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'spot_name',
              title: 'Spot name',
              description: 'Spot name',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'user_id',
              title: 'User id',
              description: 'User id',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'user_email',
              title: 'Publisher email',
              description: 'Publisher email',
              replace_with: 'original_email',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'manager_id',
              title: 'Manager id',
              description: 'Manager id',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'manager_email',
              title: 'Manager email',
              description: 'Manager email',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'tag_id',
              title: 'Tag id',
              description: 'Tag id',
              group: 'default',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'tag_name',
              title: 'Tag name',
              description: 'Tag name',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'is_shortener_tag',
              title: 'URL Shortener stats',
              description: 'Determinated if tag belongs to shortener service',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'campaign_id',
              title: 'Campaign id',
              description: 'Campaign id',
              group: 'default',
              labels: [],
            },
            {
              name: 'campaign_name',
              title: 'Campaign',
              description: 'Campaign',
              group: 'default',
              labels: [],
            },
            {
              name: 'account_id',
              title: 'Advertiser id',
              description: 'Advertiser id',
              group: 'default',
              labels: [],
            },
            {
              name: 'account_name',
              title: 'Advertiser',
              description: 'Advertiser',
              group: 'default',
              labels: [],
            },
            {
              name: 'adv_manager',
              title: 'Adv Manager',
              description: 'Adv Manager',
              group: 'default',
              labels: [],
            },
            {
              name: 'domain',
              title: 'Domain',
              description: 'Domain',
              group: 'default',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'utm_source',
              title: 'UTM Source',
              description: 'UTM Source',
              group: 'default',
              labels: [],
            },
            {
              name: 'utm_medium',
              title: 'UTM Medium',
              description: 'UTM Medium',
              group: 'default',
              labels: [],
            },
            {
              name: 'utm_campaign',
              title: 'UTM Campaign',
              description: 'UTM Campaign',
              group: 'default',
              labels: [],
            },
            {
              name: 'utm_content',
              title: 'UTM Content',
              description: 'UTM Content',
              group: 'default',
              labels: [],
            },
            {
              name: 'country',
              title: 'Country',
              description: 'Country code',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'device',
              title: 'Device',
              description: 'Device',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'browser',
              title: 'Browser',
              description: 'Browser',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'operating_system',
              title: 'OS',
              description: 'Operating System',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'click_type',
              title: 'Click Type',
              description: 'Click Type',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'feed_type',
              title: 'Feed Type',
              description: 'Feed Type',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'is_adblock',
              title: 'Is Adblock',
              description: 'Is Adblock',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'is_cryptocurrency',
              title: 'Is Cryptocurrency',
              description: 'Is Cryptocurrency',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'is_dsp',
              title: 'Is DSP',
              description: 'Is DSP',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'spot_type',
              title: 'Spot Type',
              description: 'Spot Type',
              group: 'visitors',
              labels: [],
            },
            {
              name: 'spot_size',
              title: 'Spot Size',
              description: 'Spot Size',
              group: 'default',
              labels: [],
            },
            {
              name: 'site_id',
              title: 'Site id',
              description: 'Site id',
              group: 'default',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'network_id',
              title: 'Network id',
              description: 'Network id',
              group: 'default',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'network_name',
              title: 'Network name',
              description: 'Network name',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'tag_category',
              title: 'Tag category',
              description: 'Tag category',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'tag_label',
              title: 'Tag label',
              description: 'Tag label',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'tag_custom_label',
              title: 'Tag custom label',
              description: 'Tag custom label',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'domain_moderation_status',
              title: 'Domain moderation status',
              description: 'Domain moderation status',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'domain_moderation_status_name',
              title: 'Domain moderation status name',
              description: 'Domain moderation status name',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'domain_moderation_user_email',
              title: 'Domain moderation user email',
              description: 'Domain moderation user email',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'domain_moderation_updated_at',
              title: 'Domain moderation updated at',
              description: 'Domain moderation updated at',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'target_inaccuracy',
              title: 'Target Inaccuracy',
              description: 'Target Inaccuracy',
              group: 'publishers',
              labels: {
                impersonal: true,
              },
            },
            {
              name: 'dsp_id',
              title: 'DSP',
              description: 'DSP',
              group: 'publishers',
              labels: {
                impersonal: true,
                adformat: [
                  'Native',
                  'Web Push',
                  'Inpage Push',
                  'Calendar',
                  'Popunder',
                  'In-stream ad',
                  'Banner',
                  'Extension',
                  'Mobile App',
                  'InApp',
                  'Interstitial',
                  'Direct Link',
                ],
              },
            },
            {
              name: 'traffic_source',
              title: 'Traffic source',
              description: 'Traffic source',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'detailed_traffic_source',
              title: 'Detailed Traffic source',
              description: 'Detailed Traffic source',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'money_source',
              title: 'Money source',
              description: 'Money source',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'is_own',
              title: 'Is Own',
              description: 'Is Own',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'is_ban',
              title: 'Is Ban',
              description: 'Is Ban',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'is_mediation',
              title: 'Is Mediation',
              description: 'Is Mediation',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'is_hidden_spot',
              title: 'Is Hidden Spot',
              description: 'Is Hidden Spot',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'spot_labels',
              title: 'Spot labels',
              description: 'Spot labels',
              group: 'publishers',
              labels: {
                impersonal: false,
                sign: '=@',
              },
            },
            {
              name: 'tag_test_name',
              title: 'Tag A/B Test name',
              description: 'Tag A/B Test name',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'tag_type',
              title: 'Tag A/B Test version',
              description: 'Tag A/B Test version',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'test_group',
              title: 'Test group names',
              description: 'Test group names',
              group: 'publishers',
              labels: {
                impersonal: false,
              },
            },
            {
              name: 'hour',
              title: 'Hour',
              description: 'Hour YYYY-MM-DD HH:MM::SS',
              group: 'date',
              labels: [],
            },
          ],
          operators: [
            {
              title: 'Equals',
              expression: '=',
            },
            {
              title: 'Not equals',
              expression: '!=',
            },
            {
              title: 'Less than',
              expression: '<',
            },
            {
              title: 'Less than or equals',
              expression: '<=',
            },
            {
              title: 'Greater than',
              expression: '>',
            },
            {
              title: 'Greater than or equals',
              expression: '>=',
            },
          ],
          metricGroups: [
            'traffic',
            'visitors',
            'other',
            'derivatives',
            'money',
            'GA_metrics',
            'YM_metrics',
            'metrics_without_optimizations',
            'percent',
            'unique',
            'mediation',
          ],
        },
      })
    );
  }),

  rest.get('http://localhost:8080/api/stats-presets/:slug', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: [
          {
            id: 2,
            report: 'statistics',
            name: 'Extended',
            options: {
              date: {
                date1: '2023-03-20',
                date2: '2023-03-27',
              },
              dateDiff: {
                dateDiff1: '2023-03-14',
                dateDiff2: '2023-03-21',
              },
              orderBy: 'date',
              orderDesc: true,
              computeDiff: false,
              filters: {
                adformat: {
                  data: [],
                  operator: '=',
                },
              },
              logicalFilters: [],
              dimensions: ['date', 'adformat', 'user_email'],
              metrics: [
                'impressions_admin',
                'ctr_admin',
                'clicks',
                'revenue',
                'cost',
                'profit',
                'roi',
                'ecpm',
                'epcm',
              ],
            },
            is_default: true,
            type: 'SystemPreset',
          },
          {
            id: 5,
            report: 'statistics',
            name: 'Shortener Extended',
            options: {
              date: {
                date1: '2023-03-20',
                date2: '2023-03-27',
              },
              dateDiff: {
                dateDiff1: '2023-03-14',
                dateDiff2: '2023-03-21',
              },
              orderBy: 'date',
              orderDesc: true,
              filters: {
                adformat: {
                  data: [],
                  operator: '=',
                },
              },
              logicalFilters: [],
              dimensions: ['date', 'short_id', 'adformat'],
              metrics: ['visits', 'impressions_admin', 'clicks', 'money'],
              computeDiff: false,
            },
            is_default: false,
            type: 'SystemPreset',
          },
          {
            id: 6,
            report: 'statistics',
            name: 'Revenue Difference Default',
            options: {
              date: {
                date1: '2023-03-26',
                date2: '2023-03-26',
              },
              dateDiff: {
                dateDiff1: '2023-03-25',
                dateDiff2: '2023-03-25',
              },
              orderBy: 'date',
              orderDesc: true,
              filters: {
                adformat: {
                  data: [],
                  operator: '=',
                },
              },
              logicalFilters: [
                {
                  metric: 'revenue',
                  value: 0,
                  operator: '~<',
                },
                {
                  metric: 'revenue',
                  value: 85,
                  operator: '%~>',
                },
              ],
              dimensions: ['user_email', 'adformat'],
              metrics: ['impressions_admin', 'clicks', 'revenue'],
              computeDiff: true,
            },
            is_default: false,
            type: 'SystemPreset',
          },
          {
            id: 137,
            report: 'statistics',
            name: 'User preset',
            options: {
              date: {
                date1: '2023-03-20',
                date2: '2023-03-27',
              },
              dateDiff: {
                dateDiff1: '2023-03-13',
                dateDiff2: '2023-03-20',
              },
              orderBy: 'date',
              orderDesc: true,
              computeDiff: false,
              logicalFilters: [],
              dimensions: ['date', 'adformat', 'user_email'],
              metrics: [
                'impressions_admin',
                'clicks',
                'ctr_admin',
                'revenue',
                'profit',
                'roi',
                'ecpm',
                'epcm',
              ],
              filters: {
                is_own: {
                  data: ['No'],
                  operator: '=',
                },
              },
            },
            is_default: false,
            type: 'UserPreset',
            user_id: 151,
            updated_at: '2022-12-26 12:06:43',
            created_at: '2022-12-02 14:11:40',
          },
        ],
      })
    );
  }),

  rest.get('http://localhost:8080/api/stats/:slug', (req, res, ctx) => {
    // const limit = req.url.searchParams.get('limit');
    const computeDiff = req.url.searchParams.get('computeDiff') === 'true';
    const fields = req.url.searchParams.get('fields')?.split(',') ?? [];
    const [dimension] = fields;
    const metrics = fields?.slice(1);
    const length = 125;
    return res(
      ctx.status(200),
      ctx.json({
        data: Array.from({ length }).map(() => ({
          [dimension]: `${(Math.random() * 1000).toFixed()}_d`,
          ...metrics.reduce(
            (acc, metric) => ({
              ...acc,
              [metric]: computeDiff
                ? [Math.random() * 1000, Math.random() * 1000]
                : Math.random() * 1000,
            }),
            {}
          ),
        })),
        totals: metrics.reduce((acc, metric) => ({ ...acc, [metric]: Math.random() * 1000 }), {}),
        total_rows: length * 3,
      })
      // ctx.json({
      //   data: [
      //     {
      //       spot_id: 9,
      //       date: '1',
      //       money: 256.75329694737957,
      //     },
      //     {
      //       spot_id: 9,
      //       date: '2',
      //       money: 534.8355771270061,
      //     },
      //     {
      //       spot_id: 9,
      //       date: '3',
      //       money: 256.75329694737957,
      //     },
      //     {
      //       spot_id: 9,
      //       date: '4',
      //       money: 534.8355771270061,
      //     },
      //   ],
      //   totals: {
      //     date: 757.2551581233799,
      //     money: 764.9419884712141,
      //   },
      //   total_rows: 3,
      // })
    );
  }),
];
