// import faker from '@faker-js/faker';
import { rest } from 'msw';

export const spotServiceHandlers = [
  rest.get(`${process.env.VUE_APP_POPUNDER_BASEURL}/api/tags/:tagID/spots`, (req, res, ctx) => {
    // ?page=1&limit=20&order_by=created_at&order_dir=desc&adFormat=popunder
    return res(
      ctx.status(200),
      ctx.json({
        data: [
          {
            id: 2536,
            tag_id: 19036,
            name: 'test',
            vast_url: 'https://vast.yomeno.xyz/ca?spot_id=2536',
            options: {
              exoclick_zone_id: null,
              frequency: '1',
              mediation: false,
              capping_time: '5',
              popunder_type: 'popunder',
              frequency_per_page: '1000000000',
              frequency_interval_time: '0',
              targeting: {
                allow_list: {
                  urls: [],
                  browsers: [],
                  cookies: [],
                  platforms: [],
                },
              },
            },
            blocked_verticals: [],
            hidden: false,
            sub_id: '1555766139',
            created_at: '2022-05-19T12:28:19.000000Z',
            updated_at: '2022-05-19T12:28:19.000000Z',
            deleted_at: null,
          },
          {
            id: 2538,
            tag_id: 19036,
            name: 'test_3',
            vast_url: 'https://vast.yomeno.xyz/ca?spot_id=2538',
            options: {
              exoclick_zone_id: null,
              frequency: '1',
              mediation: false,
              capping_time: '5',
              popunder_type: 'popunder',
              frequency_per_page: '1000000000',
              frequency_interval_time: '0',
              targeting: {
                allow_list: {
                  urls: [],
                  browsers: [],
                  cookies: [],
                  platforms: [],
                },
              },
            },
            blocked_verticals: [],
            hidden: false,
            sub_id: '1550522160',
            created_at: '2022-05-19T12:47:24.000000Z',
            updated_at: '2022-05-19T12:47:25.000000Z',
            deleted_at: null,
          },
          {
            id: 2540,
            tag_id: 19036,
            name: '1',
            vast_url: 'https://vast.yomeno.xyz/ca?spot_id=2540',
            options: {
              exoclick_zone_id: null,
              frequency: '1',
              mediation: false,
              capping_time: '5',
              popunder_type: 'popunder',
              frequency_per_page: '1000000000',
              frequency_interval_time: '0',
              targeting: {
                allow_list: {
                  urls: [],
                  browsers: [],
                  cookies: [],
                  platforms: [],
                },
              },
            },
            blocked_verticals: [],
            hidden: false,
            sub_id: '1012640595',
            created_at: '2022-05-19T14:46:23.000000Z',
            updated_at: '2022-05-19T14:46:24.000000Z',
            deleted_at: null,
          },
        ],
      })
    );
  }),
];
