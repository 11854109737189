
















































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import Button from '@/components/v2/Button.vue';

@Component({ name: 'Modal', components: { Button } })
export default class Modal extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: false }) hasCloseButton!: boolean;
  @Prop({ default: false }) widthFull!: boolean;
  @Prop({ default: false }) hasDoneButton!: boolean;
  @Prop({ default: 'none' }) minWidth!: string;
  @Prop({ default: 'none' }) maxWidth!: string;

  @Emit()
  change(value: boolean) {
    return value;
  }

  @Emit()
  clickOutside() {
    this.change(false);
    return;
  }

  get env() {
    return process.env;
  }

  clickClose() {
    this.clickOutside();
  }
}
