























































import { Component, Prop, Vue } from 'vue-property-decorator';

import Badge from '@/components/v2/Badge.vue';
import { ApplicationState, ISidebarPermissions } from '@/interfaces';

@Component({
  name: 'SidebarAdmin',
  components: { Badge },
})
export default class SidebarAdmin extends Vue {
  @Prop({ required: true }) metaData!: ApplicationState['metaData'];
  @Prop({ required: true }) menuOpened!: boolean;
  @Prop({ required: true }) permissions!: ISidebarPermissions;

  group = true;

  get pages() {
    return [
      {
        name: 'Users',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M6,17C6,15 10,13.9 12,13.9C14,13.9 18,15 18,17V18H6M15,9A3,3 0 0,1 12,12A3,3 0 0,1 9,9A3,3 0 0,1 12,6A3,3 0 0,1 15,9M3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5C3.89,3 3,3.9 3,5Z"
            />
          </svg>
        `,
        path: '/admin/users',
        visible: this.permissions.section_users,
      },
      {
        name: 'Payment requests',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3H5M11,6H13V9H16V11H13V14H11V11H8V9H11V6M8,16H16V18H8V16Z"
            />
          </svg>
        `,
        path: '/admin/payments',
        visible: this.permissions.admin_payment_requests,
      },
      {
        name: 'Statistics',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9 17H7V10H9V17M13 17H11V7H13V17M17 17H15V13H17V17Z"
            />
          </svg>
        `,
        path: '/admin/statistics',
        visible: this.permissions.stats,
      },
      {
        name: 'Ad format rates',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M19 3H5C3.89 3 3 3.89 3 5V19C3 20.11 3.9 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.89 20.1 3 19 3M8.83 7.05C9.81 7.05 10.6 7.84 10.6 8.83C10.6 9.81 9.81 10.6 8.83 10.6C7.84 10.6 7.05 9.81 7.05 8.83C7.05 7.84 7.84 7.05 8.83 7.05M15.22 17C14.24 17 13.45 16.2 13.45 15.22C13.45 14.24 14.24 13.45 15.22 13.45C16.2 13.45 17 14.24 17 15.22C17 16.2 16.2 17 15.22 17M8.5 17.03L7 15.53L15.53 7L17.03 8.5L8.5 17.03Z"
            />
          </svg>
        `,
        path: '/admin/stats-adformat-rates',
        visible: this.permissions.stats_adformat_rates,
      },
      {
        name: 'Spots',
        icon: `
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
          ><g clip-path="url(#a)"><path d="M10.92.745H6.622c-.272 0-.534.108-.726.3L.3 6.64C-.1 7.04-.1 7.69.3 8.092l4.298 4.299c.401.4 1.05.4 1.452 0l5.595-5.594c.192-.193.3-.455.3-.727V1.772c0-.567-.459-1.027-1.026-1.027ZM9.124 4.338a.77.77 0 1 1 0-1.54.77.77 0 0 1 0 1.54Z"/><path d="M12.973 1.772V6.55a.891.891 0 0 1-.262.632l-5.685 5.685.087.087c.4.401 1.05.401 1.452 0l5.134-5.133c.193-.192.301-.454.301-.726V2.798c0-.567-.46-1.026-1.027-1.026Z"/></g><defs>
          <clipPath id="a">
          <path d="M0 0h14v14H0z"/>
          </clipPath>
          </defs>
          </svg>
        `,
        path: '/admin/spots',
        visible: this.permissions.admin_spots,
      },
      {
        name: 'Accrual rule fixes',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9.3 8.2C10.6 8.2 11.4 8.7 12.1 9C12.7 9.3 13.4 9.7 14.5 9.7C15.5 9.7 16.5 9 17 8.5L17.8 9.8C17.1 10.6 15.8 11.4 14.4 11.4C13.1 11.4 12.3 10.9 11.7 10.6C11.1 10.3 10.3 9.9 9.2 9.9C8.2 9.9 7.2 10.6 6.7 11.1L6 9.8C6.7 9 8 8.2 9.3 8.2M14.6 15.8C13.3 15.8 12.5 15.3 11.8 15C11.2 14.7 10.4 14.3 9.3 14.3C8.3 14.3 7.3 15 6.8 15.5L6 14.1C6.7 13.3 8 12.5 9.3 12.5C10.6 12.5 11.4 13 12.1 13.3C12.7 13.6 13.4 14 14.6 14C15.6 14 16.6 13.3 17.1 12.8L17.9 14.1C17.3 15 16 15.8 14.6 15.8Z"
            />
          </svg>
        `,
        path: '/admin/accrual-rule-fixes',
        visible: this.permissions.admin_accrual_rules,
      },
      {
        name: 'Mediation',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M13.94,14.81L11.73,17C11.08,17.67 10.22,18 9.36,18C8.5,18 7.64,17.67 7,17C5.67,15.71 5.67,13.58 7,12.26L8.35,10.9L8.34,11.5C8.33,12 8.41,12.5 8.57,12.94L8.62,13.09L8.22,13.5C7.91,13.8 7.74,14.21 7.74,14.64C7.74,15.07 7.91,15.47 8.22,15.78C8.83,16.4 9.89,16.4 10.5,15.78L12.7,13.59C13,13.28 13.18,12.87 13.18,12.44C13.18,12 13,11.61 12.7,11.3C12.53,11.14 12.44,10.92 12.44,10.68C12.44,10.45 12.53,10.23 12.7,10.06C13.03,9.73 13.61,9.74 13.94,10.06C14.57,10.7 14.92,11.54 14.92,12.44C14.92,13.34 14.57,14.18 13.94,14.81M17,11.74L15.66,13.1V12.5C15.67,12 15.59,11.5 15.43,11.06L15.38,10.92L15.78,10.5C16.09,10.2 16.26,9.79 16.26,9.36C16.26,8.93 16.09,8.53 15.78,8.22C15.17,7.6 14.1,7.61 13.5,8.22L11.3,10.42C11,10.72 10.82,11.13 10.82,11.56C10.82,12 11,12.39 11.3,12.7C11.47,12.86 11.56,13.08 11.56,13.32C11.56,13.56 11.47,13.78 11.3,13.94C11.13,14.11 10.91,14.19 10.68,14.19C10.46,14.19 10.23,14.11 10.06,13.94C8.75,12.63 8.75,10.5 10.06,9.19L12.27,7C13.58,5.67 15.71,5.68 17,7C17.65,7.62 18,8.46 18,9.36C18,10.26 17.65,11.1 17,11.74Z"
            />
          </svg>
        `,
        path: '/admin/mediation',
        visible: this.permissions.admin_mediations,
      },
      {
        name: 'Landings',
        icon: `
          <svg width="14" height="14" viewBox="0 0 14 14">
            <path
              d="M6.99997 0C3.10938 0 0 3.15436 0 6.99997C0 10.8834 3.15442 14.0001 6.99836 14C10.8576 13.9999 13.9999 10.8737 13.9999 6.99997C13.9999 3.15885 10.8931 0 6.99997 0ZM5.44067 7.70782L4.925 9.94261C4.79577 10.5028 4.01711 10.5908 3.7755 10.0558L2.61536 7.48185L3.39004 5.20806C3.45659 5.0128 3.36775 4.79854 3.18258 4.70762L1.62744 3.94402C2.23168 2.8809 3.13008 2.02745 4.21776 1.48019V2.49894C4.21776 2.69267 4.35333 2.86001 4.54287 2.90018L6.64303 3.34536C6.9285 3.40573 7.15181 3.61874 7.2258 3.90114L7.42429 4.65889C7.48513 4.89161 7.43206 5.14279 7.28235 5.33078L5.51948 7.54452C5.48136 7.59237 5.45443 7.64818 5.44067 7.70782ZM6.54558 13.1633L6.78092 12.6665C6.8247 12.574 6.87332 12.5303 6.95942 12.4782C7.05028 12.4238 7.18815 12.3605 7.41483 12.2468C7.61758 12.1453 7.83518 12.2096 7.9538 12.3655C7.95383 12.3655 7.95388 12.3655 7.95391 12.3656L8.44451 13.0097C7.82539 13.1578 7.18301 13.2097 6.54558 13.1633ZM10.9827 11.7249L10.4452 11.3763L10.2042 9.14232C10.1804 8.92253 10.2375 8.70159 10.3647 8.52036L11.5752 6.79744C11.6237 6.7284 11.6498 6.64604 11.6498 6.56165V4.97914L12.4354 4.0572C12.9237 4.95677 13.1797 5.9637 13.1797 6.99997C13.1796 8.88622 12.3356 10.5818 10.9827 11.7249Z"
            />
          </svg>
        `,
        path: '/admin/landings',
        visible: this.permissions.admin_landings,
      },
      {
        name: 'Direct offer',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M5,21A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19C21,20.11 20.1,21 19,21H5M6,13H14.5L11,16.5L12.42,17.92L18.34,12L12.42,6.08L11,7.5L14.5,11H6V13Z"
            />
          </svg>
        `,
        path: '/admin/direct-offer',
        visible: this.permissions.admin_own_offers,
      },
      {
        name: 'Global spot options',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M7,7V9H9V7H7M11,7V9H13V7H11M15,7V9H17V7H15M7,11V13H9V11H7M11,11V13H13V11H11M15,11V13H17V11H15M7,15V17H9V15H7M11,15V17H13V15H11M15,15V17H17V15H15Z"
            />
          </svg>
        `,
        path: '/admin/global-spot-options',
        visible: this.permissions.spot_options,
      },
      {
        name: 'Diversification',
        icon: `
         <svg width="14" height="14" viewBox="0 0 24 24">
          <path
            d="M15.9 18.45c1.35 0 2.45-1.1 2.45-2.45s-1.1-2.45-2.45-2.45c-1.36 0-2.45 1.1-2.45 2.45s1.09 2.45 2.45 2.45m5.2-1.77l1.48 1.16c.13.11.17.29.08.45l-1.4 2.42a.35.35 0 0 1-.43.15l-1.74-.7c-.36.28-.76.51-1.18.69l-.27 1.85c-.02.17-.17.3-.34.3h-2.8c-.18 0-.32-.13-.35-.3l-.26-1.85c-.43-.18-.82-.41-1.18-.69l-1.75.7c-.15.06-.34 0-.42-.15l-1.4-2.42a.351.351 0 0 1 .08-.45l1.48-1.16l-.05-.68l.05-.69l-1.48-1.15a.353.353 0 0 1-.08-.45l1.4-2.42c.08-.16.27-.22.42-.16l1.75.71c.36-.28.75-.52 1.18-.69l.26-1.86c.03-.16.17-.29.35-.29h2.8c.17 0 .32.13.34.29l.27 1.86c.42.17.82.41 1.18.69l1.74-.71c.17-.06.34 0 .43.16l1.4 2.42c.09.15.05.34-.08.45l-1.48 1.15l.05.69l-.05.68M6.69 8.07c.87 0 1.57-.7 1.57-1.57c0-.87-.7-1.58-1.57-1.58A1.58 1.58 0 0 0 5.11 6.5c0 .87.71 1.57 1.58 1.57m3.34-1.13l.97.74c.07.07.09.19.03.29l-.9 1.56c-.05.1-.17.14-.27.1l-1.12-.45l-.74.44l-.19 1.19c-.02.11-.11.19-.22.19h-1.8c-.12 0-.21-.08-.23-.19L5.4 9.62l-.76-.44l-1.14.45c-.09.04-.2 0-.26-.1l-.9-1.56c-.06-.1-.03-.22.05-.29l.95-.74l-.03-.44l.03-.44l-.95-.74a.229.229 0 0 1-.05-.29l.9-1.56c.06-.1.17-.14.26-.1l1.13.45l.77-.44l.16-1.19c.02-.11.11-.19.23-.19h1.8c.11 0 .2.08.22.19L8 3.38l.74.44l1.12-.45c.1-.04.22 0 .27.1l.9 1.56c.06.1.04.22-.03.29l-.97.74l.03.44l-.03.44Z"
          />
         </svg>
        `,
        path: '/admin/diversification',
        visible: this.permissions.spot_optimization_rules,
      },
      {
        name: 'Spot optimization rules',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M17.25,12C17.25,12.23 17.23,12.46 17.2,12.68L18.68,13.84C18.81,13.95 18.85,14.13 18.76,14.29L17.36,16.71C17.27,16.86 17.09,16.92 16.93,16.86L15.19,16.16C14.83,16.44 14.43,16.67 14,16.85L13.75,18.7C13.72,18.87 13.57,19 13.4,19H10.6C10.43,19 10.28,18.87 10.25,18.7L10,16.85C9.56,16.67 9.17,16.44 8.81,16.16L7.07,16.86C6.91,16.92 6.73,16.86 6.64,16.71L5.24,14.29C5.15,14.13 5.19,13.95 5.32,13.84L6.8,12.68C6.77,12.46 6.75,12.23 6.75,12C6.75,11.77 6.77,11.54 6.8,11.32L5.32,10.16C5.19,10.05 5.15,9.86 5.24,9.71L6.64,7.29C6.73,7.13 6.91,7.07 7.07,7.13L8.81,7.84C9.17,7.56 9.56,7.32 10,7.15L10.25,5.29C10.28,5.13 10.43,5 10.6,5H13.4C13.57,5 13.72,5.13 13.75,5.29L14,7.15C14.43,7.32 14.83,7.56 15.19,7.84L16.93,7.13C17.09,7.07 17.27,7.13 17.36,7.29L18.76,9.71C18.85,9.86 18.81,10.05 18.68,10.16L17.2,11.32C17.23,11.54 17.25,11.77 17.25,12M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M12,10C10.89,10 10,10.89 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,10.89 13.1,10 12,10Z"
            />
          </svg>
        `,
        path: '/admin/spot-optimization-rules',
        visible: this.permissions.spot_optimization_rules,
      },
      {
        name: 'Domain moderation',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M19,3A2,2 0 0,1 21,5V19C21,20.11 20.1,21 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M16.7,9.35C16.92,9.14 16.92,8.79 16.7,8.58L15.42,7.3C15.21,7.08 14.86,7.08 14.65,7.3L13.65,8.3L15.7,10.35L16.7,9.35M7,14.94V17H9.06L15.12,10.94L13.06,8.88L7,14.94Z"
            />
          </svg>
        `,
        path: '/admin/domain-moderation',
        visible: this.permissions.domain_moderations,
      },
      {
        name: 'Revshare moderation',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M19,3A2,2 0 0,1 21,5V19C21,20.11 20.1,21 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M16.7,9.35C16.92,9.14 16.92,8.79 16.7,8.58L15.42,7.3C15.21,7.08 14.86,7.08 14.65,7.3L13.65,8.3L15.7,10.35L16.7,9.35M7,14.94V17H9.06L15.12,10.94L13.06,8.88L7,14.94Z"
            />
          </svg>
        `,
        path: '/admin/revshare-moderation',
        badge: this.metaData?.data?.created_requests ?? 0,
        visible: this.permissions.user_revshare_change_requests,
      },
      {
        name: 'Losses moderation',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M19,3A2,2 0 0,1 21,5V19C21,20.11 20.1,21 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M16.7,9.35C16.92,9.14 16.92,8.79 16.7,8.58L15.42,7.3C15.21,7.08 14.86,7.08 14.65,7.3L13.65,8.3L15.7,10.35L16.7,9.35M7,14.94V17H9.06L15.12,10.94L13.06,8.88L7,14.94Z"
            />
          </svg>
        `,
        path: '/admin/losses-moderation',
        badge: this.metaData?.data?.created_requests ?? 0,
        visible: this.permissions.losses,
      },
      {
        name: 'Accrual rules moderation',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M19,3A2,2 0 0,1 21,5V19C21,20.11 20.1,21 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M16.7,9.35C16.92,9.14 16.92,8.79 16.7,8.58L15.42,7.3C15.21,7.08 14.86,7.08 14.65,7.3L13.65,8.3L15.7,10.35L16.7,9.35M7,14.94V17H9.06L15.12,10.94L13.06,8.88L7,14.94Z"
            />
          </svg>
        `,
        path: '/admin/accrual-rules-moderation',
        visible: this.permissions.premoderations,
      },
      {
        name: 'Notifications',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M7.5,15A1.5,1.5 0 0,0 6,16.5A1.5,1.5 0 0,0 7.5,18A1.5,1.5 0 0,0 9,16.5A1.5,1.5 0 0,0 7.5,15M6,10V12A6,6 0 0,1 12,18H14A8,8 0 0,0 6,10M6,6V8A10,10 0 0,1 16,18H18A12,12 0 0,0 6,6Z"
            />
          </svg>
        `,
        path: '/admin/notifications',
        visible: this.permissions.notification_manuallies,
      },
      {
        name: 'Settings',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M8.95 13.4H6.58A5.5 5.5 0 0 1 6.58 10.6H8.95A11.56 11.56 0 0 0 8.85 12A11.56 11.56 0 0 0 8.95 13.4M7.16 9.2H9.2A12.06 12.06 0 0 1 10.18 6.71A5.55 5.55 0 0 0 7.16 9.2M16.84 9.2A5.59 5.59 0 0 0 13.81 6.71A10.95 10.95 0 0 1 14.78 9.2M12 17.57A9.5 9.5 0 0 0 13.34 14.8H10.66A9.5 9.5 0 0 0 12 17.57M12 6.42A9.53 9.53 0 0 0 10.66 9.2H13.34A9.53 9.53 0 0 0 12 6.42M7.16 14.8A5.61 5.61 0 0 0 10.18 17.29A12.06 12.06 0 0 1 9.2 14.8M21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19A2 2 0 0 1 21 5M19 12A7 7 0 1 0 12 19A7 7 0 0 0 19 12M15.15 12A11.56 11.56 0 0 1 15.05 13.4H17.42A5.5 5.5 0 0 0 17.42 10.6H15.05A11.56 11.56 0 0 1 15.15 12M13.81 17.29A5.62 5.62 0 0 0 16.84 14.8H14.78A10.95 10.95 0 0 1 13.81 17.29M10.36 10.6A8.81 8.81 0 0 0 10.36 13.4H13.64A10.3 10.3 0 0 0 13.75 12A10.21 10.21 0 0 0 13.64 10.6Z"
            />
          </svg>
        `,
        path: '/admin/settings',
        visible: this.permissions.site_settings || this.permissions.system_revshares,
      },
      {
        name: 'Audit logs',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M7,15V17H9C9.14,18.55 9.8,19.94 10.81,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19A2,2 0 0,1 21,5V13.03C19.85,11.21 17.82,10 15.5,10C14.23,10 13.04,10.37 12.04,11H7V13H10C9.64,13.6 9.34,14.28 9.17,15H7M17,9V7H7V9H17Z"
            />
          </svg>
        `,
        path: '/admin/audit-logs',
        visible: this.permissions.audits,
      },
      {
        name: 'Logs',
        icon: `
          <svg width="14" height="14" viewBox="0 0 24 24">
            <path
              d="M14,17H7V15H14M17,13H7V11H17M17,9H7V7H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
            />
          </svg>
        `,
        path: '/admin/logs',
        visible: this.permissions.logs,
      },
    ];
  }
  get env() {
    return process.env;
  }
}
