import faker from '@faker-js/faker';
import { rest } from 'msw';

export const adNetworkServiceHandlers = [
  rest.get(`${process.env.VUE_APP_BASEURL}/api/adnetworks`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: Array.from({ length: 2 }).map((_, index) => ({
          id: index + 1,
          name: faker.company.companyName(),
          identifier: faker.random.word(),
          link: faker.internet.url(),
          credentials_list: ['token'],
          link_types: faker.helpers.arrayElements(['popunder', 'banner', 'in_stream_ad']).reduce(
            (acc, adFormat) => ({
              ...acc,
              [adFormat]: faker.helpers.arrayElements(['default', 'direct']),
            }),
            {}
          ),
        })),
      })
    );
  }),

  rest.get(`${process.env.VUE_APP_BASEURL}/api/adnetworks-accounts`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: Array.from({ length: 10 }).map((_, index) => ({
          account_id: index,
          credentials: {
            token: faker.random.word(),
          },
          ad_network_id: faker.helpers.arrayElement([1, 2]),
        })),
      })
    );
  }),

  rest.get(`${process.env.VUE_APP_BASEURL}/api/adnetworks-spots/defaults`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: { percentage: faker.random.numeric(2) },
      })
    );
  }),

  rest.get(`${process.env.VUE_APP_BASEURL}/api/adnetworks-spots`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: Array.from({ length: 10 }).map((_, index) => ({
          id: index,
          spot_id: index,
          ad_network_spot_id: index,
          ad_network_id: req.url.searchParams.get('ad_network_id') || 1,
          account_id: faker.random.numeric(1),
          adformat: req.url.searchParams.get('adformat') || 1,
          spot_name: faker.random.words(3),
          ad_network_spot_name: faker.random.words(3),
          link_type: 'direct',
          direct: faker.random.word(),
          script: faker.random.word(),
          percentage: faker.random.numeric(2),
          status: 'active',
          tag_id: index,
          tag_name: faker.random.words(3),
          user_id: req.url.searchParams.get('user_id') || 1,
        })),
      })
    );
  }),
];
